@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

body {
    font-family: 'Fira Code', monospace;
    margin: 0;
    padding: 0;
    height: 100vh;
    transition:
        background-color 0.3s,
        color 0.3s;
}

body.light {
    background-color: #f7fafc;
    color: #2d3748;
}

body.dark {
    background-color: #1a202c;
    color: #e2e8f0;
}

.terminal-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
}

.terminal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.terminal-title {
    display: flex;
    align-items: center;
}

.terminal-title svg {
    width: 24px;
    height: 24px;
}

body.light .terminal-title svg {
    color: #2c5282;
}

body.dark .terminal-title svg {
    color: #68d391;
}

.terminal-title h1 {
    margin-left: 10px;
    font-size: 1.5rem;
}

body.light .terminal-title h1 {
    color: #2c5282;
}

body.dark .terminal-title h1 {
    color: #68d391;
}

.theme-toggle {
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
}

.terminal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.terminal-output {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.terminal-body > div {
    font-size: 1.1rem;
    padding: 8px 0;
}

.welcome-message {
    margin-bottom: 20px;
    font-weight: bold;
    /* color: #4299e1; */
    color: #4a5568;
}

body.dark .welcome-message {
    /* color: #63b3ed; */
    color: #a0aec0;
}

.old-prompt {
    font-weight: bold;
    font-size: 1.1rem !important;
}

body.light .old-prompt {
    color: #2c5282;
}

body.dark .old-prompt {
    color: #68d391;
}

.output {
    padding-left: 20px;
}

body.light .output {
    color: #4a5568 !important;
}

body.dark .output {
    color: #a0aec0 !important;
}

.terminal-input {
    display: flex;
    padding: 10px;
    font-size: 1.1rem;
    align-items: center;
}

.terminal-input span {
    margin-right: 10px;
    font-size: 1.1rem;
    font-weight: bold;
}

body.light .terminal-input span {
    color: #2c5282;
}

body.dark .terminal-input span {
    color: #68d391;
}

.terminal-input input {
    flex: 1;
    background: transparent;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: 1.1rem;
}

.terminal-input input:focus {
    outline: none;
}

body.light .terminal-input input {
    color: #2d3748;
}

body.dark .terminal-input input {
    color: #f7fafc;
}
